import { Elm } from './Main.elm';
import 'configurable-date-input-polyfill';


Elm.Main.init({
  node: document.getElementById('root')
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})