module Main exposing (Model, Msg(..), ReadcodeEntry, SnomedEntry, combine, init, main, readcodeListDecoder, search_readcode, search_snomed, snomedListDecoder, subscriptions, update, view, viewResult, viewStatus)

import Array exposing (Array)
import Browser
import Date
import Html exposing (..)
import Html.Attributes as A exposing (attribute, class, href)
import Html.Events as E
import Html.Events.Extra exposing (onChange)
import Http
import Json.Decode as D exposing (Decoder, Value, array, decodeValue, field, index, list, map2, map3, string)
import List.Extra exposing (uniqueBy)
import Task
import Time


main =
    Browser.element
        { init = init
        , update = update
        , subscriptions = subscriptions
        , view = view
        }



-- MODEL


type alias Model =
    { snomed : Array SnomedEntry
    , readcode : Array ReadcodeEntry
    , query : String
    , length : Int
    , buttonUnit : Date.Unit
    , today : Date.Date
    , injuryDate : Date.Date
    }


init : () -> ( Model, Cmd Msg )
init _ =
    ( { snomed = Array.empty
      , readcode = Array.empty
      , query = ""
      , length = 3
      , buttonUnit = Date.Days
      , today = Date.fromPosix Time.utc (Time.millisToPosix 0)
      , injuryDate = Date.fromPosix Time.utc (Time.millisToPosix 0)
      }
    , Cmd.batch
        [ Http.get
            { url = "mappings.json"
            , expect = Http.expectJson GotSnomed snomedListDecoder
            }
        , Http.get
            { url = "read.json"
            , expect = Http.expectJson GotReadcode readcodeListDecoder
            }
        , Task.perform GotTodayDate Date.today
        ]
    )



-- Decoders


type alias SnomedEntry =
    { preferredName : String
    , readTerm : String
    , readCode : String
    }


type alias ReadcodeEntry =
    { readTerm : String
    , readCode : String
    }


snomedListDecoder : Decoder (Array SnomedEntry)
snomedListDecoder =
    array <|
        map3 SnomedEntry
            (index 0 string)
            (index 2 string)
            (index 1 string)


readcodeListDecoder : Decoder (Array ReadcodeEntry)
readcodeListDecoder =
    array <|
        map2 ReadcodeEntry
            (index 1 string)
            (index 0 string)



-- UPDATE


type Msg
    = Query String
    | Calculate Date.Unit Int
    | ChangeUnit Date.Unit
    | ChangeLength String
    | ChangeInjuryDate String
    | GotSnomed (Result Http.Error (Array SnomedEntry))
    | GotReadcode (Result Http.Error (Array ReadcodeEntry))
    | GotTodayDate Date.Date


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        GotSnomed result ->
            case result of
                Ok snomed ->
                    ( { model | snomed = snomed }
                    , Cmd.none
                    )

                Err _ ->
                    ( model, Cmd.none )

        GotReadcode result ->
            case result of
                Ok readcode ->
                    ( { model | readcode = readcode }
                    , Cmd.none
                    )

                Err _ ->
                    ( model, Cmd.none )

        GotTodayDate today ->
            ( { model | today = today, injuryDate = today }
            , Cmd.none
            )

        Query string ->
            ( { model | query = string }
            , Cmd.none
            )

        Calculate unit length ->
            ( { model | length = length, buttonUnit = unit }
            , Cmd.none
            )

        ChangeUnit unit ->
            ( { model | buttonUnit = unit }
            , Cmd.none
            )

        ChangeLength length_string ->
            let
                length =
                    String.toInt length_string
            in
            case length of
                Just l ->
                    ( { model | length = l }
                    , Cmd.none
                    )

                Nothing ->
                    ( { model | length = 0 }
                    , Cmd.none
                    )

        ChangeInjuryDate dateString ->
            ( { model
                | injuryDate =
                    Result.withDefault model.today <|
                        Date.fromIsoString
                            dateString
              }
            , Cmd.none
            )



-- SUBSCRIPTIONS


subscriptions model =
    Sub.none



-- VIEW


view : Model -> Html Msg
view model =
    div [ A.class "container" ]
        [ viewPromotion
        , div [ class "content" ]
            [ viewMainInterface model
            , viewResult model
            ]
        , viewFooter
        ]


viewMainInterface : Model -> Html Msg
viewMainInterface model =
    div [ A.class "jumbotron" ]
        [ h1 [ A.class "text-center" ] [ text "Easy Read Code", small [] [ text " 2023" ] ]
        , viewStatus model
        , viewSearchBar
        , viewDateCalculator model.buttonUnit model.length model.today model.injuryDate
        ]


viewPromotion =
    div [ A.class "alert alert-info promotion text-center" ]
        [ text " 😖 Preparing for ACEM Primary or GSSE? "
        , text "Checkout my new project "
        , a
            [ A.href "https://www.acetheexam.co.nz"
            , A.target "_blank"
            , A.class "acetheexam"
            ]
            [ text "Ace the Exam" ]
        , text " 🤘"
        ]


viewDateCalculator : Date.Unit -> Int -> Date.Date -> Date.Date -> Html Msg
viewDateCalculator unit length today injuryDate =
    div [ A.class "row date-group" ]
        [ div [ A.class "col-xs-8 col-xs-offset-2 col-sm-6 col-sm-offset-0 col-md-6 col-lg-4" ]
            [ h5 [ A.class "text-center" ] [ text "Injury date" ]
            , input
                [ A.attribute "type" "date"
                , A.class "form-control text-center date-polyfill"
                , onChange ChangeInjuryDate
                , A.id "starting-date-input"
                , A.value <| Date.toIsoString injuryDate
                ]
                []
            ]
        , div [ A.class "col-xs-12 col-sm-6 col-md-6 col-lg-4 text-center" ]
            [ h5 [ A.class "text-center" ]
                [ text <| "unfit for work for " ++ String.fromInt length ++ " " ++ dateUnitToString unit ]
            , div [ A.class "row" ]
                [ div [ A.class "input-group-btn ", A.attribute "role" "group" ]
                    [ button
                        [ A.type_ "button"
                        , A.class "btn btn-default"
                        , E.onClick (Calculate Date.Days 3)
                        ]
                        [ text "3 days" ]
                    , button
                        [ A.type_ "button"
                        , A.class "btn btn-default"
                        , E.onClick (Calculate Date.Days 7)
                        ]
                        [ text "7 days" ]
                    , button
                        [ A.type_ "button"
                        , A.class "btn btn-default"
                        , E.onClick (Calculate Date.Days 10)
                        ]
                        [ text "10 days" ]
                    , button
                        [ A.type_ "button"
                        , A.class "btn btn-default"
                        , E.onClick (Calculate Date.Days 14)
                        ]
                        [ text "14 days" ]
                    ]
                , div [ A.class "input-group input-group-sm input-date-length" ]
                    [ input
                        [ A.type_ "number"
                        , A.class "form-control"
                        , A.placeholder ""
                        , E.onInput ChangeLength
                        ]
                        []
                    , div [ A.class "input-group-btn" ]
                        [ button
                            [ A.type_ "button"
                            , A.class "btn btn-default dropdown-toggle"
                            , A.attribute "data-toggle" "dropdown"
                            ]
                            [ text (dateUnitToString unit)
                            , span [ A.class "caret" ] []
                            ]
                        , ul [ A.class "dropdown-menu dropdown-menu-right" ]
                            [ li [] [ a [ A.href "#", E.onClick (ChangeUnit Date.Days) ] [ text "days(s)" ] ]
                            , li [] [ a [ A.href "#", E.onClick (ChangeUnit Date.Weeks) ] [ text "week(s)" ] ]
                            , li [] [ a [ A.href "#", E.onClick (ChangeUnit Date.Months) ] [ text "month(s)" ] ]
                            ]
                        ]
                    ]
                ]
            ]
        , div [ A.class "col-xs-8 col-xs-offset-2 col-lg-offset-0 col-lg-4" ]
            [ h5 [ A.class "text-center" ] [ text "until:" ]
            , div [ A.class "well well-sm text-center" ]
                [ strong [] [ text (calculateDateSinceInjury injuryDate length unit) ]
                ]
            ]
        ]


dateUnitToString : Date.Unit -> String
dateUnitToString unit =
    case unit of
        Date.Days ->
            "day(s)"

        Date.Weeks ->
            "week(s)"

        Date.Months ->
            "month(s)"

        _ ->
            ""


calculateDateSinceInjury : Date.Date -> Int -> Date.Unit -> String
calculateDateSinceInjury injuryDate length unit =
    let
        endDate =
            case unit of
                Date.Days ->
                    Date.add unit length injuryDate

                Date.Weeks ->
                    Date.add unit length injuryDate

                Date.Months ->
                    Date.add unit length injuryDate

                _ ->
                    injuryDate
    in
    Date.format "EEEE d/MM/y" endDate


viewSearchBar : Html Msg
viewSearchBar =
    input
        [ A.type_ "text"
        , A.autofocus True
        , A.class "search form-control input-lg"
        , A.placeholder "Enter a diagnosis, e.g. hand laceration"
        , E.onInput Query
        ]
        []


viewStatus : Model -> Html Msg
viewStatus model =
    let
        loaded =
            not (Array.isEmpty model.snomed) && not (Array.isEmpty model.readcode)
    in
    h5 [ A.class "text-right" ]
        [ text "Database Status: "
        , span
            [ A.class
                ("glyphicon "
                    ++ (if loaded then
                            "glyphicon-ok-sign"

                        else
                            "glyphicon-refresh spinning"
                       )
                )
            , A.style "color"
                (if loaded then
                    "green"

                 else
                    "orange"
                )
            , A.attribute "data-toggle" "tooltip"
            , A.attribute "data-placement" "top"
            , A.attribute "data-original-title"
                (if loaded then
                    "Database fully loaded"

                 else
                    "Database loading..."
                )
            ]
            []
        ]


viewResult : Model -> Html Msg
viewResult model =
    let
        snomed_result =
            Array.filter (search_snomed model.query) model.snomed

        readcode_result =
            if Array.isEmpty snomed_result then
                Array.filter (search_readcode model.query) model.readcode

            else
                Array.empty

        result =
            combine snomed_result readcode_result
    in
    div [ A.class "results col-xs-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2" ]
        [ h3 [ A.class "text-center" ]
            [ text
                (if not (List.isEmpty result) then
                    "Results"

                 else
                    ""
                )
            ]
        , table [ A.class "table table-bordered table-hover table-striped" ]
            [ tbody []
                (List.map viewResultRow result)
            ]
        ]


viewResultRow : ReadcodeEntry -> Html Msg
viewResultRow entry =
    tr []
        [ td [] [ text entry.readCode ]
        , td [] [ text entry.readTerm ]
        ]


combine : Array SnomedEntry -> Array ReadcodeEntry -> List ReadcodeEntry
combine snomed_result readcode_result =
    let
        cleaned_snomed_result =
            Array.map (\e -> { readTerm = e.readTerm, readCode = e.readCode }) snomed_result

        result =
            Array.append cleaned_snomed_result readcode_result
    in
    uniqueBy (\e -> e.readTerm) <| Array.toList result


type CodeType
    = Readcode ReadcodeEntry
    | Snomed SnomedEntry


search_snomed : String -> SnomedEntry -> Bool
search_snomed query entry =
    search (Snomed entry) query


search_readcode : String -> ReadcodeEntry -> Bool
search_readcode query entry =
    search (Readcode entry) query


search : CodeType -> String -> Bool
search entry query =
    if (String.length <| String.trim query) <= 3 then
        False

    else
        let
            q_list =
                String.split " " (String.toLower query)

            name =
                case entry of
                    Readcode e ->
                        String.toLower e.readTerm

                    Snomed e ->
                        String.toLower e.preferredName
        in
        List.all (\q -> String.contains q name) q_list


viewFooter =
    div [ class "footer" ]
        [ div [ class "row" ]
            [ div [ class "col-xs-4" ]
                [ p [ class "text-center" ]
                    [ a [ href "disclaimer.html" ]
                        [ text "Disclaimer and copyright" ]
                    ]
                ]
            , div [ class "col-xs-4" ]
                [ p [ class "text-muted text-center" ]
                    [ text "Built with                "
                    , span
                        [ attribute "aria-hidden" "true"
                        , class "glyphicon glyphicon-heart"
                        , attribute "data-placement" "top"
                        , attribute "data-toggle" "tooltip"
                        , attribute "style" "color:red; margin-right: 4px"
                        ]
                        []
                    , text "by Tubo Shi"
                    , text " | "
                    , a [ href "https://www.buymeacoffee.com/tuboshi" ] [ text "Buy me a coffee?" ]
                    ]
                ]
            , div [ class "col-xs-4" ]
                [ p [ class "text-center" ]
                    [ a [ href "mailto:shi@tubo.nz?subject=Easy Read Code User" ]
                        [ text "Comments and feedback" ]
                    ]
                ]
            ]
        ]
